import React from "react";
import styles from "../page.module.css";

const HeroSection = () => {
  return (
    <div id="hero" className={styles.heroSection}>
      <div className={styles.heroContentContainer}>
        <p className={styles.title1} style={{ margin: "0px" }}>
          Bringing Health Solutions Across Borders
        </p>
        <p className={styles.title2} style={{ marginTop: "24px" }}>
          Your Trusted Source for Quality Medicine. Ensuring genuine medications
          for a healthier nation, we supply safe and effective pharmaceutical
          imports. With excellence and competitive pricing
        </p>
        <div>
          <a href="/products">
            <button className={styles.button} style={{ marginTop: "24px" }}>
              Browse our products
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
