import * as Yup from "yup";

export const AddItemlValidationSchema = Yup.object({
  title: Yup.string().required("This is required"),
  description: Yup.string().required("This is required"),
  code: Yup.string().required("This is required"),
  brand: Yup.string().required("This is required"),
  ingredients: Yup.string().required("This is required"),
  moreInformation: Yup.string().required("This is required"),
  usageInstructions: Yup.string().required("This is required"),
  imageUrl: Yup.string().required("This is required"),
});
