import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Navbar/NavBar";
import AboutSection from "./home/aboutSection";
// import PreviewerComponent from "./home/branding/Previewer";
import BrandingSection from "./home/branding/brandingSection";
import ContactSection from "./home/contactSection";
import Footer from "./home/footer";
import HeroSection from "./home/heroSection";
import "./page.module.css";
import Products from "./products/ProductsContainer";
import Item from "./item/ItemContainer";
import { Admin } from "./admin/admin";
import { AddItemForm } from "./admin/addItemForm";
import { Login } from "./admin/login";
import { EditItemForm } from "./admin/editItemForm";
import { AddGroup } from "./admin/addgroup";
import { EditBrandForm } from "./admin/editGroup";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/edit/:id" element={<EditItemForm />} />
        <Route path="/admin/editbrand/:id" element={<EditBrandForm />} />
        <Route path="/addItem" element={<AddItemForm />} />
        <Route path="/addGroup" element={<AddGroup />} />
        <Route path="/products" element={<Products />}></Route>
        <Route path="/products/:id" element={<Item />} />
        <Route path="/*" element={<LandingPage />} />
      </Routes>
    </BrowserRouter>
  );
}

function LandingPage() {
  return (
    <main className="main">
      <Navbar></Navbar>
      <HeroSection />
      <BrandingSection />
      {/* <PreviewerComponent /> */}
      <AboutSection />
      <ContactSection />
      <Footer></Footer>
    </main>
  );
}
