import React, { useState, useEffect } from "react";
import styles from "./navbarStyle.module.css";
import Logo from "../logo";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div
      className={styles.navbar}
      style={{
        backgroundColor: "#0a1931",
      }}
    >
      <a href="/" style={{ padding: 0, margin: 0 }}>
        <Logo color={"#fff"}></Logo>
      </a>

      <div className={styles.normalMenu}>
        <Link
          to="/products"
          style={{
            backgroundColor: "#0a1931",
          }}
        >
          Products
        </Link>
        <a
          href="/#about"
          style={{
            backgroundColor: "#0a1931",
          }}
        >
          About us
        </a>
        <a
          href="/#contact"
          style={{
            backgroundColor: "#0a1931",
          }}
        >
          Contact us
        </a>
      </div>
      <div className={styles.burgerMenu}>
        {" "}
        <Link
          to="/products"
          style={{
            backgroundColor: "#0a1931",
          }}
        >
          Products
        </Link>
      </div>
    </div>
  );
}

export default Navbar;
