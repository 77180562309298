import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCyoceJxZFtLD3_HqOy6ScYJ9Qwm4IKLSA",
  authDomain: "mwamedical-9402c.firebaseapp.com",
  projectId: "mwamedical-9402c",
  storageBucket: "mwamedical-9402c.appspot.com",
  messagingSenderId: "705290276460",
  appId: "1:705290276460:web:a12c417b2cc92a286bd249",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
export { app, storage, auth, db };
