import { useEffect, useState } from "react";
import "./admin.css";
import { Link } from "react-router-dom";
import { getDocs, collection } from "firebase/firestore";
import { auth, db } from "./firebase";
import { Item } from "../item/ItemContainer";
import { Login } from "./login";
import { useAuth } from "../hooks/useAuth";
import Logo from "../logo";
import { signOut } from "firebase/auth";
import { Footer } from "./components";

const handleLogOut = () => {
  signOut(auth).catch((error) => {
    console.log(error);
  });
};

export interface Brand {
  title: string;
  code: string;
  imageUrl: string;
}

export function Admin() {
  const user = useAuth();

  if (user === null) {
    return (
      <div className="App">
        <Login />
      </div>
    );
  }
  return <AdminComponent />;
}

function AdminComponent() {
  const [allItems, setAllItems] = useState<Item[]>([]);
  const [brand, setBrands] = useState<Brand[]>([]);
  const loadData = async () => {
    const querySnapshot = await getDocs(collection(db, "items"));
    const items = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...(doc.data() as Item),
    }));
    setAllItems(items);
  };
  const loadGroup = async () => {
    const querySnapshot = await getDocs(collection(db, "brands"));
    const items = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...(doc.data() as Brand),
    }));
    setBrands(items);
  };
  useEffect(() => {
    loadGroup();
    loadData();
  }, []);
  return (
    <div
      style={{
        height: "100vh",
        padding: "0px 80px",
      }}
    >
      <div
        style={{
          padding: "16px 0px",
          borderBottom: "1px solid #e0e0e0",
          marginBottom: "16px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <a href="/" style={{ padding: 0, margin: 0 }}>
          <Logo color={"#0a1931"}></Logo>
        </a>
        <button
          className="button2"
          onClick={(e) => handleLogOut()}
          style={{ padding: "8px 16px" }}
        >
          Logout
        </button>
      </div>
      <div>
        <Link to={"/addGroup"}>
          <button className="button">Add Group</button>
        </Link>
        <div className="grid" style={{ marginTop: "16px" }}>
          {brand.map((x) => (
            <DisplayBrand key={x.code} item={x} />
          ))}
        </div>
        <div style={{ marginTop: "24px" }}>
          <Link to={"/addItem"}>
            <button className="button">Add Item</button>
          </Link>
        </div>
        <div></div>
        <div className="grid" style={{ marginTop: "16px" }}>
          {allItems.map((x) => (
            <DisplayItem key={x.code} item={x} />
          ))}
        </div>
      </div>
      <div style={{ marginTop: "16px" }}>
        <Footer></Footer>
      </div>
    </div>
  );
}

export const DisplayItem = ({ item }: { item: Item }) => (
  <Link to={`/admin/edit/${item.code}`}>
    <div className="gridItem">
      <img src={item.imageUrl} alt={item.title} />
      <p className="gridItemTitle" style={{ color: "#000" }}>
        {item.title}
      </p>
    </div>
  </Link>
);
export const DisplayBrand = ({ item }: { item: Brand }) => (
  <Link to={`/admin/editbrand/${item.code}`}>
    <div className="gridItem">
      <img src={item.imageUrl} alt={item.title} />
      <p className="gridItemTitle" style={{ color: "#000" }}>
        {item.title}
      </p>
    </div>
  </Link>
);
