import { Form, Formik } from "formik";
import { doc, setDoc } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { db } from "./firebase";
import { ImageUploadField, InputField } from "./components";
import { useAuth } from "../hooks/useAuth";
import { Login } from "./login";

export function AddGroup() {
  const user = useAuth();

  if (user === null) {
    return (
      <div className="App">
        <Login />
      </div>
    );
  }
  return <AddGroupComponent />;
}
function AddGroupComponent() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        padding: "80px",
      }}
    >
      <Formik
        initialValues={{
          code: "",
          title: "",
          imageUrl: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await setDoc(doc(db, "brands", values.code), {
            title: values.title,
            code: values.code,
            imageUrl: values.imageUrl,
          })
            .then((e) => navigate("/admin"))
            .catch((error) => window.alert(error));
        }}
      >
        {({ values, isValid, dirty, isSubmitting, setFieldValue }) => (
          <Form>
            <InputField label={"Brand Title"} name={"title"}></InputField>
            <InputField label={"Brand Code"} name={"code"}></InputField>
            <div style={{ margin: "16px 0px" }}>
              <ImageUploadField name={"imageUrl"} />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                className="button"
                type="submit"
                style={{ marginTop: "24px" }}
                disabled={!isValid || !dirty || isSubmitting}
              >
                Add Item
              </button>
              <Link to={"/admin"}>
                <button className="button2" style={{ marginTop: "24px" }}>
                  Cancel
                </button>
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
