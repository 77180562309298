import React from "react";
import Logo from "../logo";
import styles from "../page.module.css";
import LogoFooter from "../logoFooter";

const Footer = () => {
  return (
    <div className={styles.footerSection}>
      <div>
        <LogoFooter color="white"></LogoFooter>
      </div>
      <div style={{ display: "flex", flexDirection: "row", marginTop: "56px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <img
            src="/icons/phone.svg"
            alt=""
            style={{ width: "24px", height: "24px" }}
          />
        </div>
        <p className={styles.footerFont}>+90 535 252 22 82</p>
      </div>
      <div style={{ display: "flex", flexDirection: "row", marginTop: "24px" }}>
        <div
          style={{
            display: "flex",
            padding: "16px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/icons/Location.svg"
            alt=""
            style={{ width: "24px", height: "24px" }}
          />
        </div>
        <p className={styles.footerFont}>
          SERHAT MAH. 1125 CAD. NO: 20 /3 YENIMAHALLE/ ANKARA / Turkey
        </p>
      </div>
    </div>
  );
};

export default Footer;
