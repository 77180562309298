import React from "react";
import styles from "../page.module.css";

const AboutSection = () => {
  return (
    <div className={styles.aboutSection} id="about">
      <p className={styles.aboutTitle}>About Us</p>
      <p className={styles.aboutDesc} style={{ marginTop: "5rem" }}>
        At MWA Medicine, we understand the importance of access to genuine
        medications for a healthier nation. Thats why we are committed to
        ensuring the availability of safe and effective pharmaceutical imports.
        We work closely with reputable Turkish manufacturers and suppliers to
        deliver a wide range of high-quality medications to our valued customers
        in Iraq.
      </p>
      <p className={styles.aboutDesc} style={{ marginTop: "1.5rem" }}>
        Our mission is to empower lives through the provision of top-notch
        pharmaceutical products. We believe that everyone deserves access to
        safe and effective medicines, and we strive to make a positive impact on
        healthcare outcomes in Iraq. By maintaining strict quality control
        measures and adhering to international standards, we guarantee that
        every product we export meets the highest level of excellence.
      </p>
      <p className={styles.aboutDesc} style={{ marginTop: "1.5rem" }}>
        We take great pride in our comprehensive selection of certified
        medicines, covering various therapeutic areas. Whether you are a patient
        seeking specific treatments or a healthcare provider in need of reliable
        pharmaceutical supplies, we are here to serve you. Our dedicated team of
        experts is well-versed in the pharmaceutical industry and is committed
        to delivering exceptional customer service.
      </p>
      <p className={styles.aboutDesc} style={{ marginTop: "1.5rem" }}>
        Transparency, reliability, and affordability are the cornerstones of our
        business philosophy. We work tirelessly to establish long-term
        partnerships with our customers, ensuring that their needs are met with
        utmost care and professionalism. With competitive pricing and a
        commitment to customer satisfaction, we aim to make healthcare
        accessible and affordable for all.
      </p>
      <p className={styles.aboutDesc} style={{ marginTop: "1.5rem" }}>
        Thank you for choosing MWA Medicine as your trusted partner in
        healthcare. We invite you to explore our website and discover the
        quality products and services we offer. Should you have any questions or
        inquiries, our friendly customer support team is always ready to assist
        you. Together, lets pave the way for a healthier future in Iraq through
        reliable and quality-driven pharmaceutical exports.
      </p>
    </div>
  );
};

export default AboutSection;
