import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { AddItemlValidationSchema } from "./AddItemValidationSchema";
import { db } from "./firebase";
import { Item } from "../item/ItemContainer";
import { BrandsOptions, ImageUploadField, InputField } from "./components";
import { useAuth } from "../hooks/useAuth";
import { Login } from "./login";

export function EditItemForm() {
  const user = useAuth();

  if (user === null) {
    return (
      <div className="App">
        <Login />
      </div>
    );
  }
  return <EditItemFormComponent />;
}

export function EditItemFormComponent() {
  const [initialValues, setInitialValues] = useState<Item>({
    title: "",
    description: "",
    code: "",
    brand: "",
    ingredients: "",
    moreInformation: "",
    usageInstructions: "",
    imageUrl: "",
  });
  const [itemId, setItemId] = useState<string>("");
  const navigate = useNavigate();
  const findProductByTitle = async (itemId: string) => {
    const docRef = doc(db, "items", itemId);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data() as Item;

    if (docSnap.exists()) {
      console.log("all set");
      return data;
    } else {
      return {
        title: "",
        description: "",
        code: "",
        brand: "",
        ingredients: "",
        moreInformation: "",
        usageInstructions: "",
        imageUrl: "",
      };
    }
  };

  useEffect(() => {
    const { pathname } = window.location;
    const itemId = decodeURIComponent(pathname?.split("/").pop() || "");
    setItemId(itemId);
    const foundProduct = findProductByTitle(itemId);
    if (foundProduct) {
      foundProduct.then((x) => setInitialValues(x));
    }
  }, []);

  return (
    <div
      style={{
        padding: "80px",
      }}
    >
      <button
        className="button3"
        onClick={async (e) => {
          await deleteDoc(doc(db, "items", itemId)).then((e) =>
            navigate("/admin")
          );
        }}
      >
        Delete
      </button>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={AddItemlValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await updateDoc(doc(db, "items", itemId), {
            title: values.title,
            description: values.description,
            code: values.code,
            brand: values.brand,
            ingredients: values.ingredients,
            moreInformation: values.moreInformation,
            usageInstructions: values.usageInstructions,
            imageUrl: values.imageUrl,
          })
            .then((e) => navigate("/admin"))
            .catch((error) => window.alert(error));
        }}
      >
        {({ values, isValid, dirty, isSubmitting, setFieldValue }) => (
          <Form>
            <InputField label={"Product Title"} name={"title"}></InputField>
            <InputField
              label={"Product Description"}
              name={"description"}
            ></InputField>

            <InputField label={"Product Code"} name={"code"}></InputField>
            <InputField
              label={"Product Ingredients"}
              name={"ingredients"}
            ></InputField>
            <InputField
              label={"More Information"}
              name={"moreInformation"}
            ></InputField>
            <InputField
              label={"Usage Instructions"}
              name={"usageInstructions"}
            ></InputField>
            <div style={{ margin: "16px 0px" }}>
              <p className="Tx16M24">Product Brand</p>
              <Field
                as="select"
                name="brand"
                style={{ width: "100%", padding: "16px 24px" }}
              >
                <BrandsOptions></BrandsOptions>
              </Field>
            </div>
            <ImageUploadField name={"imageUrl"} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                className="button"
                type="submit"
                style={{ marginTop: "24px" }}
                disabled={!isValid || !dirty || isSubmitting}
              >
                Update Item
              </button>
              <Link to={"/admin"}>
                <button className="button2" style={{ marginTop: "24px" }}>
                  Cancel
                </button>
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
