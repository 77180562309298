import React from "react";
import styles from "../../page.module.css";
import { BrandsData } from "../../admin/components";

const BrandingSection = () => {
  const brands = BrandsData();
  return (
    <div className={styles.brandingPage}>
      <div>
        <p className={styles.brandingTitle}>MWA MEDCINE GROUP COMPANIES</p>
        <p className={styles.brandingDescription} style={{ marginTop: "40px" }}>
          Introducing our esteemed partners, a distinguished group of companies
          whose exceptional products we proudly import. Together, we strive to
          deliver cutting-edge solutions for better healthcare outcomes.
        </p>
      </div>
      <div className={styles.bradningGrid} style={{ marginTop: "80px" }}>
        {brands.map((x) => {
          return (
            <div className={styles.brandingGridItem}>
              <img
                src={x.imageUrl}
                alt={x.title}
                className={styles.brandImage}
              ></img>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BrandingSection;
