import React from "react";
import styles from "../page.module.css";
import WhatsappButton from "./branding/whatsapp";

const ContactSection = () => {
  return (
    <div className={styles.contactSection} id="contact">
      <p className={styles.contactTitle}>Got any questions?</p>
      <p className={styles.contactDescription} style={{ marginTop: "2rem" }}>
        If you have any questions, or simply want to chat with us, contact us
        through Whatsapp using this number
      </p>
      <div style={{ marginTop: "2rem" }}>
        <a aria-label="Chat on WhatsApp" href="https://wa.me/905352522282">
          <WhatsappButton></WhatsappButton>
        </a>
      </div>
    </div>
  );
};

export default ContactSection;
