"use client";
import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/NavBar";
import styles from "./products.module.css";
import products from "./data.json";
import { Item } from "../item/ItemContainer";
import { Link } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../admin/firebase";
import { BrandsData } from "../admin/components";

const Products = () => {
  const [items, setItems] = useState<any[]>([]);
  const [brand, setBrand] = useState<string>("");
  const loadData = async () => {
    const querySnapshot = await getDocs(collection(db, "items"));
    const items = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setItems(items);
  };
  const loadBrandData = async (brand: string) => {
    const q = query(collection(db, "items"), where("brand", "==", brand));

    const querySnapshot = await getDocs(q);
    const items = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setItems(items);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={styles.main}>
      <Navbar></Navbar>
      <div className={styles.container}>
        <CatagoriesList
          Selected={loadBrandData}
          AllData={loadData}
          setBrand={setBrand}
        ></CatagoriesList>
        <ItemGrid items={items} brand={brand} />
      </div>
    </div>
  );
};

const CatagoriesList = ({
  Selected,
  AllData,
  setBrand,
}: {
  Selected: (brand: string) => Promise<void>;
  AllData: () => Promise<void>;
  setBrand: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const brands = BrandsData();
  return (
    <div className={styles.sideBar}>
      <div
        className={styles.catagoryButton}
        onClick={(e) => {
          AllData();
          setBrand("All Items");
        }}
      >
        All Items
      </div>
      <div
        style={{
          borderBottom: "1px solid #DEDFE0",
          margin: "8px 0px",
        }}
      ></div>

      {brands.map((x) => {
        return (
          <>
            <div
              className={styles.catagoryButton}
              onClick={(e) => {
                Selected(x.code);
                setBrand(x.title);
              }}
            >
              {x.title}
            </div>
            <div
              style={{
                borderBottom: "1px solid #DEDFE0",
                margin: "8px 0px",
              }}
            ></div>
          </>
        );
      })}
    </div>
  );
};

export const DisplayItem = ({ item }: { item: Item }) => (
  <Link to={`/products/${item.code}`}>
    <div className={styles.gridItem}>
      <img src={item.imageUrl} alt={item.title} />
      <p className={styles.gridItemTitle} style={{ color: "#000" }}>
        {item.title}
      </p>
    </div>
  </Link>
);

const ItemGrid = ({ items, brand }: { items: any[]; brand: string }) => {
  const [itemsToShow, setItemsToShow] = useState(24);

  const loadMoreItems = () => {
    setItemsToShow(itemsToShow + 24);
  };

  return (
    <div>
      <div className={styles.rowContainer}>
        <div id={brand}>
          <p
            className={styles.subtitle}
            id={brand}
            style={{ marginBottom: "40px" }}
          >
            {brand}
          </p>
          <div className={styles.row}>
            {items.slice(0, itemsToShow).map((item) => (
              <DisplayItem key={item.index} item={item} />
            ))}
            {itemsToShow < products.length && (
              <button onClick={loadMoreItems}>Load more</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
