"use client";
import React, { useEffect, useState } from "react";
import styles from "./item.module.css";
import Navbar from "../Navbar/NavBar";
import products from "../products/data.json";
import WhatsappButton from "../home/branding/whatsapp";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../admin/firebase";

export interface Item {
  title: string;
  description: string;
  brand: string;
  usageInstructions: string;
  moreInformation: string;
  imageUrl: string;
  code: string;
  ingredients: string;
}

const Item = () => {
  const [product, setProduct] = useState<Item | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const { pathname } = window.location;
    const itemId = decodeURIComponent(pathname?.split("/").pop() || "");
    const foundProduct = findProductByTitle(itemId);

    if (foundProduct) {
      foundProduct.then((x) => setProduct(x));
    } else {
      setProduct(null);
    }

    setIsLoading(false);
  }, []);

  const findProductByTitle = async (itemId: string) => {
    const docRef = doc(db, "items", itemId);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data() as Item;

    if (docSnap.exists()) {
      return data;
    } else {
      return null;
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!product) {
    return <p>Product not found</p>;
  }

  return (
    <>
      <Navbar></Navbar>
      <div className={styles.container}>
        <div className={styles.infoContainer}>
          <div className={styles.imgFrame}>
            <div>
              <img
                className={styles.img}
                src={product.imageUrl}
                alt={product.title}
              ></img>
            </div>
          </div>
          <div className={styles.informationSide}>
            <div>
              <p className={styles.title}>{product.title}</p>
              <p className={styles.subtitle} style={{ marginTop: "8px" }}>
                {product.description}
              </p>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <p className={styles.proptitle} style={{ marginTop: "48px" }}>
                    Type:
                  </p>
                  <p className={styles.proptitle} style={{ marginTop: "16px" }}>
                    Brand :
                  </p>
                </div>
                <div style={{ marginLeft: "24px", marginTop: "32px" }}>
                  <p className={styles.propinfo}>{product.ingredients}</p>
                  <p className={styles.propinfo} style={{ marginTop: "16px" }}>
                    {product.brand}
                  </p>
                </div>
              </div>
            </div>
            <a
              aria-label="Order through WhatsApp"
              href="https://wa.me/905352522282"
              className={styles.whatsappButton}
            >
              <WhatsappButton></WhatsappButton>
            </a>
          </div>
        </div>
        <div style={{ marginTop: "24px" }}>
          <p className={styles.title2}>More Information</p>
          <p className={styles.textarea} style={{ marginTop: "24px" }}>
            {product.moreInformation}
          </p>
          <p className={styles.proptitle} style={{ marginTop: "40px" }}>
            Usage Instruction:
          </p>
          <p className={styles.textarea} style={{ marginTop: "16px" }}>
            {product.usageInstructions}
          </p>
        </div>
      </div>
      <div className={styles.whatsappSticky}>
        <a
          aria-label="Order through WhatsApp"
          href="https://wa.me/905352522282"
        >
          <WhatsappButton></WhatsappButton>
        </a>
      </div>
    </>
  );
};

export default Item;
